<template>
  <v-form>
    <v-container fluid>
      <v-card max-width="800px" class="mt-4 mx-auto">
        <v-card-title v-text="$t('TenantSeminarSettings')" />
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h3 v-text="$t('seminarNumberRange')"></h3>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                :error-messages="formatErrors"
                :label="$t('format')"
                @blur="$v.item.numberRange.format.$touch()"
                @input="$v.item.numberRange.format.$touch()"
                required
                v-model="item.numberRange.format"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                :error-messages="startNumberErrors"
                :label="$t('startNumber')"
                @blur="$v.item.numberRange.startNumber.$touch()"
                @input="$v.item.numberRange.startNumber.$touch()"
                required
                v-model="item.numberRange.startNumber"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                :error-messages="numberCycleErrors"
                :label="$t('numberCycle')"
                @blur="$v.item.numberRange.numberCycle.$touch()"
                @input="$v.item.numberRange.numberCycle.$touch()"
                required
                :items="numberCycleValues"
                v-model.number="item.numberRange.numberCycle"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                :label="$t('maxParticipants')"
                @blur="$v.item.maxParticipants.$touch()"
                @input="$v.item.maxParticipants.$touch()"
                required
                v-model.number="item.maxParticipants"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                :label="$t('defaultModuleFee')"
                @blur="$v.item.defaultModuleFee.$touch()"
                @input="$v.item.defaultModuleFee.$touch()"
                prefix="€"
                required
                v-model.number="item.defaultModuleFee"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-checkbox
                :label="$t('individuallyBookable')"
                @blur="$v.item.individuallyBookable.$touch()"
                @input="$v.item.individuallyBookable.$touch()"
                v-model="item.individuallyBookable"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                :label="$t('offerable')"
                @blur="$v.item.offerable.$touch()"
                @input="$v.item.offerable.$touch()"
                v-model="item.offerable"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                :label="$t('faoTrainingEnabled')"
                @blur="$v.item.faoTrainingEnabled.$touch()"
                @input="$v.item.faoTrainingEnabled.$touch()"
                v-model="item.faoTrainingEnabled"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                :label="$t('publicVisibility')"
                @blur="$v.item.publicVisibility.$touch()"
                @input="$v.item.publicVisibility.$touch()"
                v-model="item.publicVisibility"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                :label="$t('publicBookable')"
                @blur="$v.item.publicBookable.$touch()"
                @input="$v.item.publicBookable.$touch()"
                v-model="item.publicBookable"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                :label="$t('noOnline')"
                @blur="$v.item.noOnline.$touch()"
                @input="$v.item.noOnline.$touch()"
                v-model="item.noOnline"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox
                :label="$t('presenceSeminarsEnabled')"
                @blur="$v.item.presenceSeminarsEnabled.$touch()"
                @input="$v.item.presenceSeminarsEnabled.$touch()"
                v-model="item.presenceSeminarsEnabled"
              />
            </v-col>
          </v-row>
          <v-row>
          <v-col cols="12">
            <h3 v-text="$t('contactOnRegister')"></h3>
          </v-col>
          <v-col cols="12">
            <v-card flat>
                <ContactList
                  :contact-objects="contactsObjects"
                  :contacts-server-length="contactsLength"
                  :handle-created="onContactCreated"
                  :handle-deleted="onContactDeleted"
                  :allowed-contact-types="allowedContactTypes"
                  :tenant-seminar-settings-id="item['@id']"
                />
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>

      </v-card>
    </v-container>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import has from "lodash/has";
import ContactList from "@/components/contact/ContactList";
import ContactMixin from "@/mixins/ContactMixin";

export default {
  components: {
    ContactList,
  },
  name: "TenantSeminarSettingForm",
  mixins: [validationMixin, ContactMixin],
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    initialValues: {
      type: Object,
      default: () => {},
    },
    handleSubmit: {
      type: Function,
      required: false,
    },
    handleReset: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      maxParticipants: null,
      defaultModuleFee: null,
      individuallyBookable: false,
      offerable: false,
      faoTrainingEnabled: false,
      publicVisibility: false,
      publicBookable: false,
      noOnline: false,
      numberRange: {},
      numberCycleValues: [
        { text: this.$t("Annual"), value: 0 },
        { text: this.$t("Monthly"), value: 10 },
        { text: this.$t("Never"), value: 30 },
        //{text: this.$t('Weekly'), value: 20}
      ],
      allowedContactTypes: [
          {text: this.$t('mail_module_booking'), value: 'mail_module_booking'},
          {text: this.$t('mail_bcc'), value: 'mail_bcc'},
      ]
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    maxParticipantsErrors() {
      const errors = [];

      if (!this.$v.item.maxParticipants.$dirty) {
        return errors;
      }
      has(this.violations, "maxParticipants") &&
        errors.push(this.violations.maxParticipants);

      !this.$v.item.maxParticipants.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    defaultModuleFeeErrors() {
      const errors = [];
      if (!this.$v.item.defaultModuleFee.$dirty) {
        return errors;
      }
      has(this.violations, "defaultModuleFee") &&
        errors.push(this.violations.defaultModuleFee);
      !this.$v.item.defaultModuleFee.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    individuallyBookableErrors() {
      const errors = [];

      if (!this.$v.item.individuallyBookable.$dirty) {
        return errors;
      }

      has(this.violations, "individuallyBookable") &&
        errors.push(this.violations.individuallyBookable);

      !this.$v.item.individuallyBookable.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    offerableErrors() {
      const errors = [];

      if (!this.$v.item.offerable.$dirty) {
        return errors;
      }

      has(this.violations, "offerable") &&
        errors.push(this.violations.offerable);
      return errors;
    },
    publicVisibilityErrors() {
      const errors = [];

      if (!this.$v.item.publicVisibility.$dirty) {
        return errors;
      }

      has(this.violations, "publicVisibility") &&
        errors.push(this.violations.publicVisibility);

      return errors;
    },
    publicBookableErrors() {
      const errors = [];

      if (!this.$v.item.publicBookable.$dirty) {
        return errors;
      }

      has(this.violations, "publicBookable") &&
        errors.push(this.violations.publicBookable);

      return errors;
    },
    formatErrors() {
      const errors = [];

      if (!this.$v.item.numberRange.format.$dirty) {
        return errors;
      }

      has(this.violations, "format") && errors.push(this.violations.format);

      !this.$v.item.numberRange.format.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    startNumberErrors() {
      const errors = [];

      if (!this.$v.item.numberRange.startNumber.$dirty) {
        return errors;
      }

      has(this.violations, "startNumber") &&
        errors.push(this.violations.startNumber);

      !this.$v.item.numberRange.startNumber.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    numberCycleErrors() {
      const errors = [];

      if (!this.$v.item.numberRange.numberCycle.$dirty) {
        return errors;
      }

      has(this.violations, "numberCycle") &&
        errors.push(this.violations.numberCycle);

      !this.$v.item.numberRange.numberCycle.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    noOnliineErrors() {
      const errors = [];

      if (!this.$v.item.noOnline.$dirty) {
        return errors;
      }

      has(this.violations, "noOnline") && errors.push(this.violations.noOnline);

      !this.$v.item.noOnline.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    violations() {
      return this.errors || {};
    },
    contactsObjects() {
      if (Array.isArray(this.item.bookingMailContacts) === false) {
        return [];
      }
      return this.item.bookingMailContacts.map((contactId) =>
        this.resolveContact(contactId)
      );
    },
    contactsLength() {
      if (Array.isArray(this.item.bookingMailContacts) === false) {
        return 0;
      }
      return this.item.bookingMailContacts.length
    }
  },
  methods: {
    onContactCreated(createdContact) {
      this.item.bookingMailContacts.push(createdContact["@id"]);
    },
    onContactDeleted(deletedContact) {
       this.item.bookingMailContacts.splice(
        this.item.bookingMailContacts.indexOf(deletedContact["@id"]),
        1
      );
    },
  },
  validations: {
    item: {
      maxParticipants: {
        required,
      },
      defaultModuleFee: {
        required,
      },
      individuallyBookable: {},
      offerable: {},
      faoTrainingEnabled: {},
      publicVisibility: {},
      publicBookable: {},
      noOnline: {},
      numberRange: {
        format: {
          required,
        },
        startNumber: {
          required,
        },
        numberCycle: {
          required,
        },
      },
    },
  },
};
</script>
